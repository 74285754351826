<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent scrollable max-width="725px">
      <v-card>
        <v-card-title>
          <v-row>
            <v-col md="3">
              Imprimir
            </v-col>
            <v-col md="8" />
            <v-col @click="closeModal">
              <h5 class="closeX">X</h5>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text  id="tableExport" style="height: 500px; width:700px">
          <v-container>
            <v-row justify="center">
              <v-col md="12" class="pl-8" align="center">

                <v-row justify="center" class="boxContent">
                  <v-col cols="4">
                    <img src="https://app.finago.com.br/img/logo.b7bde43d.png" width="150">
                  </v-col>
                  <v-col md="8">
                    <span class="dateTop">
                      Extrato referente ao periodo <strong> de {{$moment(days[0]).format('DD/MM/YYYY')}}
                      até {{$moment(days[1]).format('DD/MM/YYYY')}}</strong>
                    </span>
                  </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="4" class="titleHeaders">
                      {{getDigitalAccount ? getDigitalAccount.name : null }}
                    </v-col>
                    <v-col cols="4" class="titleHeaders">
                      Agência
                    </v-col>
                    <v-col cols="4" class="titleHeaders">
                      Conta Corrente
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="dataHeaders">
                      {{getDigitalAccount ? service.documentFormat(getDigitalAccount.document) : null }}
                    </v-col>
                    <v-col cols="4" class="dataHeaders">
                      {{getDigitalAccount ? getDigitalAccount.branch : null }}
                    </v-col>
                    <v-col cols="4" class="dataHeaders">
                      {{getDigitalAccount ? getDigitalAccount.account : null }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                <v-divider style="background-color: #282E62;"></v-divider>
                </v-col>
                <v-col align="left" class="pl-5" cols="12">
                  <v-row>
                    <v-col class="titleHeaders" cols="8">
                      Extrato conta corrente
                    </v-col>
                    <v-col class="dataHeaders" cols="4">
                      Extrato dia {{`${$moment().format('DD/MM/YYYY')} ${$moment().format('HH:mm:ss')}`}}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col  cols="12">
                  <v-data-table
                    hide-default-footer
                    :headers="treatedHeaders"
                    :items="dataTableItems"
                    :items-per-page="99999999999"
                    :custom-sort="service.customSort"

                  >
                    <template v-slot:item.date="{ item }">
                          <p style="text-align:center">{{item.date}}</p>
                    </template>
                    <template v-slot:item.description="{ item }">
                          <p style="text-align:center">{{item.description}}</p>
                    </template>
                    <template v-slot:item.value="{ item }">
                      <p v-if="item.type === 'D'" class="debit">{{`-${item.value}` }}</p>
                      <p v-if="item.type === 'C'" class="credit">{{`+${item.value}` }}</p>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col class="warningContent" md="12">
                  <p class="warningTitle">Aviso</p>
                  <p class="warningText">Os saldos acima são baseados nas informações disponíveis até esse
                    instante e poderão ser alterados a qualquer momento em função de novos lançamentos</p>
                </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-container fluid>
            <v-row class="infoConfig" justify="center">
              <v-col align="center" md="7">
                A configuração padrão dessa impressão é no tamanho A4 e pode variar de acordo com a
                impressora selecionada
              </v-col>
              <v-col md="12">
                <v-row>
                  <v-col>
                    <v-btn color="#D70472" class="button" rounded width="220" dark @click="print">Imprimir</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import { mapGetters } from 'vuex';
import service from '../../../service';

export default {
  props: {
    dialog: Boolean,
    dataTableItems: Array,
    headers: Array,
    days: Array,
  },
  data() {
    return {
      showModal: true,
      service,
    };
  },
  computed: {
    ...mapGetters({
      getDigitalAccount: 'DigitalAccount/getAccount',
    }),
    treatedHeaders(){
      delete this.headers[3];
      return this.headers
    }
  },
  methods: {
    print() {
      const prtHtml = document.getElementById('tableExport').innerHTML;
      let styles = '';
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        styles += node.outerHTML;
      }
      const printWindow = window.open('', '', 'left=0,top=0,height=900,toolbar=100,scrollbars=0,status=0');

      printWindow.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${styles}
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`);
      this.$emit('closeModal', false);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    },
    closeModal() {
      this.$emit('closeModal', false);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/digitalAccount/print.scss';
  @import '~@/assets/styles/supplier/detail.scss';

</style>
