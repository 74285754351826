<template>
<div>
    <span :class="titleStyle">{{title}}</span>
  <div class="cardContent">
    <h5 class="value">{{value}}</h5>
    <h5 class="date">{{date}}</h5>
  </div>
</div>
</template>

<script>

export default {
  name: 'Card',
  props: {
    value: { String, Number },
    date: String,
    title: String,
    titleStyle: {
      type: String,
      validator(value) {
        return ['success', 'warning', 'danger'].includes(value);
      },

    },
  },
};

</script>

<style lang="scss" scoped>
.success {
  font-weight: bold;
  font-size: 15px;
  color: #00c1c1;
}
.danger {
  font-weight: bold;
  font-size: 15px;
  color: #D70472;
}
.warning {
  color:#FFFFFF
}
.cardContent {
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 8px;
  height: 100px;
  width: 100%;

  }
.value{
width: 100%;
height: 53px;
margin-left: 10%;
margin-top: 4%;
font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 47px;
line-height: 59px;
letter-spacing: -0.022em;

color: #8C8C8C;
}
.date {
width: 103px;
height: 20px;
margin-left: 10.5%;
margin-top: 2%;

font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 20px;
/* identical to box height */

letter-spacing: -0.022em;

color: #8C8C8C;
}
@media only screen and (max-device-width: 767px) {
  /* For mobile phones: */
  .cardContent {
    width: 100%;
  }
  .value {
    font-size: 200%;
    margin-left: 7%;
  }
}
</style>
