<template>
  <v-container>
    <ModalReceipt :dialog="dialogReceipt" @closeModalReceipt="closeModalReceipt" />
    <ModalReceiptPix :dialog="dialogReceiptPix" @closeModalReceiptPix="closeModalReceiptPix" />
    <ModalDevolutionPix
      :dialog="dialogDevolutionPix"
      @closeModalDevolutionPix="closeModalDevolutionPix"
    />
    <v-col class="pb-0">
      <v-row class="cards">
        <v-col md="12" v-if="snackbar">
          <v-snackbar
            class="toastSnack"
            v-model="snackbar"
            :color="color"
            :top="true"
            :multi-line="false"
            :vertical="false"
          >
            {{ error }}
            <v-btn dark text @click="snackbar = false">X</v-btn>
          </v-snackbar>
        </v-col>
        <v-col md="6" cols="12" class="pt-3 pt-md-0">
          <Card
            :value="service.formatCurrency(balance)"
            title="Saldo Conta Corrente"
            class="success-title"
          />
        </v-col>
        <v-col md="3" cols="7" class="btn-save mt-md-3 pb-0">
          <v-col class="save-img">
            <img src="../../../assets/save-icon.svg" class="save-icon" alt="save-icon" />
          </v-col>
          <v-col @click="exportStatement" class="save-col pt-0">
            <p class="save-text">Salvar como CSV</p>
          </v-col>
        </v-col>
        <v-col @click="openModal" md="2" cols="4" class="btn-print mt-md-3 pb-0">
          <v-col class="print-img">
            <img src="../../../assets/print-icon.svg" class="print-icon" alt="print-icon" />
          </v-col>
          <v-col class="print-col pt-0">
            <p class="print-text">Imprimir</p>
          </v-col>
        </v-col>
      </v-row>
    </v-col>
    <v-col md="12" class="pt-0" v-if="$vuetify.breakpoint.smAndDown">
      <v-divider></v-divider>
    </v-col>
    <v-col md="12" class="pt-md-0 pb-md-0">
      <v-row class="my-0">
        <v-col md="5" cols="12" class="pt-0 ml-md-3">
          <v-row class="my-0">
            <v-col md="6" cols="6" class="pr-1 pl-md-0 pl-3">
              <v-btn @click="entry" class="button" :outlined="entryStyle" color="#00C1C1" dark>
                Lançamentos
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col md="12" class="pb-0 pt-0" v-if="$vuetify.breakpoint.mdAndUp">
      <v-row>
        <v-col md="1" cols="12" class="mt-0 mt-md-4">
          <span class="filter">Filtros</span>
        </v-col>
        <v-col class="buttons-filter">
          <v-row>
            <v-col md="12" cols="12" class="filter-category">
              <v-row justify="end" class="ml-0">
                <v-col md="4" class="pa-0 mr-3">
                  <v-btn
                    @click="openModalFilterPeriod"
                    class="button-filter mr-3"
                    :outlined="!datesRangeIsActive"
                    :color="datesRangeIsActive ? '#D70472' : '#8C8C8C'"
                    dark
                    v-model="datesRangeIsActive"
                  >
                    {{ datesRangeIsActive ? dateRangeText : "Período" }}
                    <v-icon right>
                      mdi-chevron-down
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col md="3" class="pa-0 mr-3">
                  <v-btn
                    @click="filterInflow"
                    class="button-filter"
                    v-model="inflow"
                    :outlined="!inflow"
                    :color="inflow ? '#D70472' : '#8C8C8C'"
                    dark
                  >
                    Entrada
                  </v-btn>
                </v-col>
                <v-col md="3" class="pa-0 mr-3">
                  <v-btn
                    @click="filterOutflow"
                    class="button-filter"
                    v-model="outflow"
                    dark
                    :outlined="!outflow"
                    :color="outflow ? '#D70472' : '#8C8C8C'"
                    >Saída</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="2" class="orderBy mt-5"> Ordernar por:</v-col>
        <v-col md="2" @click="ordenation" class="ordemValue mt-5 onClick">
          {{ orderBy ? "Mais recentes" : "Menos recentes" }}
        </v-col>
        <v-col md="1" @click="ordenation">
          <v-icon v-if="orderBy" class="mt-4 onClick">mdi-chevron-up</v-icon>
          <v-icon v-if="!orderBy" class="mt-4 onClick">mdi-chevron-down</v-icon>
        </v-col>
      </v-row>
    </v-col>
    <v-col md="12" cols="12" class="pt-md-0 pa-0 pa-md-3 pb-md-8">
      <v-divider></v-divider>
    </v-col>
    <div v-if="$vuetify.breakpoint.smAndDown">
      <v-col @click="clickShow" class="filter-mobile arrow">
        <span> Filtrar por</span>
        <v-icon v-if="showPeriod">mdi-chevron-down</v-icon>
        <v-icon v-if="!showPeriod">mdi-chevron-up</v-icon>
      </v-col>
      <v-divider></v-divider>
      <div v-if="!showPeriod">
        <v-col
          class="filter-mobile"
          :class="{ 'filter-period-mobile': datesRangeIsActive }"
          @click="openModalFilterPeriod"
        >
          <span>{{ datesRangeIsActive ? `Período: ${dateRangeText}` : "Período" }}</span>
        </v-col>
        <v-divider></v-divider>
        <v-col
          class="filter-mobile"
          :class="{ 'filter-period-mobile': inflow }"
          @click="filterInflow"
        >
          <span>Entrada</span>
        </v-col>
        <v-divider></v-divider>
        <v-col
          class="filter-mobile"
          :class="{ 'filter-period-mobile': outflow }"
          @click="filterOutflow"
        >
          <span>Saída</span>
        </v-col>
        <v-divider></v-divider>
        <v-col>
          <v-btn class="btn-filter" color="#D70472" rounded height="50" block dark>
            Filtrar
          </v-btn>
        </v-col>
      </div>
    </div>
    <v-col v-show="!getLoading" cols="12">
      <v-data-table
        hide-default-footer
        :headers="headers"
        :items="dataTableItems"
        :items-per-page="9999999999"
        :mobile-breakpoint="600"
        class="dataTableContent"
        :custom-sort="service.customSort"
      >
        <template v-slot:[`item.value`]="{ item }">
          <span v-if="item.type === 'D'" class="debit">{{ `-${item.value}` }}</span>
          <span v-if="item.type === 'C'" class="credit">{{ `+${item.value}` }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-col cols="12" class="pa-0">
            <v-btn
              v-if="
                (item.type === 'D' && !item.description.includes('DEVOLUCAO DE PIX')) ||
                  (item.type === 'C' && !item.description.includes('VIA PIX'))
              "
              class="button-statement-table"
              outlined
              small
              rounded
              @click="openReceipt(item.transactionId)"
            >
              Ver
            </v-btn>
            <v-btn
              v-if="item.type === 'C' && item.description.includes('VIA PIX')"
              class="button-statement-table"
              outlined
              small
              rounded
              @click="openReceiptPix(item.transactionId)"
            >
              Ver
            </v-btn>
            <v-btn
              v-if="item.type === 'D' && item.description.includes('DEVOLUCAO DE PIX')"
              class="button-statement-table"
              outlined
              small
              rounded
              @click="openDevolutionPix(item.transactionId)"
            >
              Ver
            </v-btn>
          </v-col>
        </template>
      </v-data-table>

      <v-dialog
        v-model="dialogDateFilter"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        persistent
        max-width="450"
        class="dialog pt-12"
      >
        <v-col cols="12" class="pt-12 mt-3 mt-md-0 pa-0 pa-md-0">
          <v-card>
            <v-date-picker
              class="date-picker-filter"
              locale="pt-BR"
              full-width
              no-title
              v-model="datesRange"
              range
              color="#D70472"
              :min="datesRange.length === 1 ? getMaxDateRange[0] : ''"
              :max="datesRange.length === 1 ? getMaxDateRange[1] : ''"
            ></v-date-picker>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-col cols="6">
                <v-btn
                  class="button-date-picker"
                  @click="cancelDateFilter"
                  color="#D70472"
                  rounded
                  dark
                  >Cancelar</v-btn
                >
              </v-col>
              <v-col cols="6">
                <v-btn
                  class="button-date-picker"
                  :class="{ 'disable-events': datesRange.length <= 1 }"
                  @click="selectRangeDate"
                  color="#00C1C1"
                  rounded
                  dark
                  >Filtrar
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-dialog>

      <Print
        :dialog="dialog"
        @closeModal="closeModal"
        :dataTableItems="dataTableItems"
        :headers="headers"
        :days="datesRange"
      />
    </v-col>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Card from "../../../components/Card.vue";
import Print from "./PrintStatement.vue";
import service from "../../../service";
import ModalReceipt from "./ModalReceipt.vue";
import ModalReceiptPix from "./ModalReceiptPix.vue";
import ModalDevolutionPix from "./ModalDevolutionPix.vue";
import roles from "../../../config/permissions";

export default {
  name: "BankStatement",
  components: {
    Card,
    Print,
    ModalReceipt,
    ModalReceiptPix,
    ModalDevolutionPix
  },
  data: () => ({
    orderBy: true,
    dialog: false,
    setFilterButton: false,
    cancelModal: true,
    entryStyle: false,
    newEntryStyle: true,
    color: "red",
    periodModal: false,
    showModal: false,
    showPeriod: true,
    outflow: false,
    inflow: false,
    snackbar: false,
    desc: false,
    dialogReceipt: false,
    dialogReceiptPix: false,
    dialogDevolutionPix: false,
    dialogDateFilter: false,
    hasPermission: false,
    headers: [
      {
        text: "Data",
        align: "center",
        filterable: false,
        sortable: false,
        value: "date"
      },
      {
        text: "Nº do pedido",
        align: "center",
        filterable: false,
        sortable: false,
        value: "order"
      },
      {
        text: "Descrição",
        align: "center",
        filterable: false,
        sortable: false,
        value: "description"
      },
      {
        text: "Valor",
        align: "center",
        filterable: false,
        sortable: false,
        value: "value"
      },
      {
        text: "Ação",
        align: "center",
        filterable: false,
        sortable: false,
        value: "actions"
      }
    ],
    day: { period: {} },
    service,
    datesRange: [],
    maxDateRange: 90,
    datesRangeIsActive: false,
    defaultDateRange: 7,
    textReceipt: false
  }),
  watch: {
    orderBy() {
      this.desc = !this.desc;
    }
  },
  computed: {
    dateRangeText() {
      const dates = this.service.sortDates(this.datesRange);
      dates[0] = this.$moment(dates[0]).format("DD/MM/YYYY");
      if (dates.length > 1) {
        dates[1] = this.$moment(dates[1]).format("DD/MM/YYYY");
      }
      return dates.join(" - ");
    },
    getMaxDateRange() {
      const minDate = this.$moment(this.datesRange[0]),
        minDateF = minDate.subtract(this.maxDateRange - 1, "days").format("YYYY-MM-DD"),
        maxDate = this.$moment(this.datesRange[0]),
        maxDateF = maxDate.add(this.maxDateRange - 1, "days").format("YYYY-MM-DD");
      return [minDateF, maxDateF];
    },
    getResultDateRange() {
      const dates = this.service.sortDates(this.datesRange);
      if (dates.length > 1)
        return this.$moment.duration(this.$moment(dates[1]).diff(this.$moment(dates[0]))).asDays();
      return 0;
    },
    dataTableItems() {
      const sortedData = this.service.dataSort(this.getStatement, "entryDate", this.desc);
      return sortedData.map(el => ({
        date: this.$moment(el.entryDate).format("DD/MM/YYYY"),
        transactionId: el.transactionId,
        order: el.comment,
        description: el.description,
        value: this.service.formatCurrency(el.amount),
        type: el.type
      }));
    },
    ...mapGetters({
      getStatement: "DigitalAccount/getConsultStatement",
      getFileName: "DigitalAccount/getFileName",
      getStatementFile: "DigitalAccount/getFile",
      error: "DigitalAccount/getError",
      balance: "DigitalAccount/getBalance",
      getLoading: "Loading/getLoading"
    })
  },
  methods: {
    ...mapActions({
      downloadFile: "DigitalAccount/downloadStatement",
      setBalance: "DigitalAccount/setBalance",
      setConsultStatement: "DigitalAccount/consultStatement",
      receiptTransfer: "DigitalAccount/receiptTransfer",
      hasRole: "User/hasRole"
    }),
    ...mapMutations({
      setStatement: "DigitalAccount/SET_CONSULT_STATEMENT"
    }),
    ordenation() {
      this.orderBy = !this.orderBy;
    },
    forceFileDownload() {
      const url = window.URL.createObjectURL(new Blob(["\ufeff", this.getStatementFile]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${this.getFileName}.csv`);
      document.body.appendChild(link);
      link.click();
    },
    filterButton() {
      this.setFilterButton = true;
      this.cancelModal = false;
    },
    cancel() {
      this.cancelModal = true;
    },
    closeModal(ev) {
      this.dialog = ev;
    },
    async exportStatement() {
      const { day } = this;
      await this.downloadFile({ ...day });
      if (!this.error) {
        this.forceFileDownload();
      } else {
        this.snackbar = true;
      }
    },
    clickShow() {
      this.showModal = !this.showModal;
      if (this.showModal === false) {
        this.showPeriod = false;
      } else if (this.showModal === true) {
        this.showPeriod = true;
      }
    },
    entry() {
      if (this.entryStyle === true) {
        this.entryStyle = false;
        this.newEntryStyle = true;
      }
    },
    selectPeriod() {
      this.periodModal = true;
    },
    closeModalPeriod() {
      this.periodModal = false;
    },
    async filterInflow() {
      if (this.inflow === false) {
        this.inflow = true;
        this.outflow = false;
        this.orderBy = true;
        const { day } = this;
        await this.filterPeriod({ ...day, direction: "input" });
        if (this.error) this.snackbar = true;
      } else {
        this.inflow = false;
        this.orderBy = true;
        const { day } = this;
        await this.filterPeriod({ ...day, direction: null });
      }
    },
    async filterOutflow() {
      if (this.outflow === false) {
        this.outflow = true;
        this.inflow = false;
        this.orderBy = true;
        const { day } = this;
        await this.filterPeriod({ ...day, direction: "output" });
        if (this.error) this.snackbar = true;
      } else {
        this.outflow = false;
        this.orderBy = true;
        const { day } = this;
        await this.filterPeriod({ ...day, direction: null });
      }
    },
    async filterPeriod(payload) {
      this.day = payload;
      if (this.outflow === true) {
        const { day } = this;
        this.orderBy = true;
        await this.setConsultStatement({ ...day, direction: "output" });
      } else if (this.inflow === true) {
        const { day } = this;
        this.orderBy = true;
        await this.setConsultStatement({ ...day, direction: "input" });
      } else if (this.error) {
        this.snackbar = true;
      } else {
        const { day } = this;
        this.orderBy = true;
        await this.setConsultStatement({ ...day, direction: null });
      }
    },
    async selectRangeDate() {
      this.datesRange = this.service.sortDates(this.datesRange);
      this.day = {
        period: {
          startDate: `${this.datesRange[0]}T00:00:01Z`,
          endDate: `${this.datesRange[1]}T23:59:59Z`
        }
      };
      const { day } = this;
      this.datesRangeIsActive = true;
      this.dialogDateFilter = false;
      this.orderBy = true;
      if (this.outflow) {
        return await this.setConsultStatement({ ...day, direction: "output" });
      }
      if (this.inflow) {
        return await this.setConsultStatement({ ...day, direction: "input" });
      }
      if (this.error) {
        return (this.snackbar = true);
      }
      if (!this.outflow && !this.inflow && !this.error) {
        return await this.setConsultStatement({ ...day, direction: null });
      }
    },
    async cancelDateFilter() {
      this.day = { period: {} };
      await this.initialDatesRange(this.defaultDateRange);
      if (this.outflow || this.inflow) await this.selectRangeDate();
      this.dialogDateFilter = false;
      this.datesRangeIsActive = false;
    },
    openModal() {
      this.dialog = true;
    },
    async openReceipt(payload) {
      try {
        this.textReceipt = true;
        await this.receiptTransfer(payload);
        this.textReceipt = false;
        this.dialogReceipt = true;
      } catch {
        this.snackbar = true;
      }
    },
    async openReceiptPix(payload) {
      try {
        this.textReceipt = true;
        await this.receiptTransfer(payload);
        this.textReceipt = false;
        this.dialogReceiptPix = true;
      } catch {
        this.snackbar = true;
      }
    },

    async openDevolutionPix(payload) {
      try {
        this.textReceipt = true;
        await this.receiptTransfer(payload);
        this.textReceipt = false;
        this.dialogDevolutionPix = true;
      } catch {
        this.snackbar = true;
      }
    },
    closeModalReceipt() {
      this.dialogReceipt = false;
    },
    closeModalReceiptPix() {
      this.dialogReceiptPix = false;
    },

    closeModalDevolutionPix() {
      this.dialogDevolutionPix = false;
    },
    async openModalFilterPeriod() {
      this.dialogDateFilter = true;
    },
    async initialDatesRange(period = this.defaultDateRange) {
      const { day } = this;
      await this.setConsultStatement(day);
      const currentDate = this.$moment(),
        defaultPeriod = this.$moment(),
        defaultPeriodF = defaultPeriod.subtract(period - 1, "days");
      const initialRange = [
        this.$moment(currentDate).format("YYYY-MM-DD"),
        this.$moment(defaultPeriodF).format("YYYY-MM-DD")
      ];
      this.datesRange = this.service.sortDates(initialRange.slice());
    },
    async initialize() {
      this.initialDatesRange(this.defaultDateRange);
      await this.setBalance();
      if (this.error) {
        this.snackbar = true;
      }
    }
  },
  async created() {
    await this.initialize();
    this.hasPermission = await this.hasRole(roles.CUSTOMER_WALLET_PAYMENT_WRITE.role);
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/digitalAccount/bankStatement.scss";
@import "~@/assets/styles/digitalAccount/modalReceipt.scss";
</style>
