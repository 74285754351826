<template>
  <v-row v-if="receipt" justify="center">
    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      class="dialog"
      persistent
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline pb-0">
          <v-col
            cols="10"
            class="modal-title"
            v-if="receipt.type === 'BankTransfer' || receipt.type === 'InternalTransfer'"
            >Comprovante de Transferência</v-col
          >
          <v-col cols="10" class="modal-title" v-if="receipt.type === 'Payment'"
            >Comprovante de Pagamento</v-col
          >
          <v-col cols="10" class="modal-title" v-if="receipt.type === 'InstantPayment'"
            >Comprovante Pix</v-col
          >
          <v-spacer></v-spacer>
          <v-col class="icon" cols="1">
            <v-icon @click="closeModal" class="close-icon">mdi-window-close</v-icon>
          </v-col>
        </v-card-title>
        <v-divider class="mx-6 ml-6"></v-divider>

        <v-card-text id="contentReceipt" class="pb-0">
          <v-container>
            <v-row justify="center">
              <v-col cols="12" align="center">
                <h5
                  class="sendTransfer"
                  v-if="receipt.type === 'BankTransfer' || receipt.type === 'InternalTransfer'"
                >
                  Transferência Enviada
                </h5>
                <h5 class="sendTransfer" v-if="receipt.type === 'Payment'">Pagamento efetuado</h5>
                <h5 class="sendTransfer" v-if="receipt.type === 'InstantPayment'">
                  Operação Pix efetuada
                </h5>
              </v-col>
              <v-col cols="12" align="center">
                <h5 class="valuePayment">Valor do Pagamento</h5>
              </v-col>
              <v-col class="contentValue" align="center">
                <h2>{{ service.formatCurrency(receipt.value) }}</h2>
              </v-col>
              <v-col cols="12" align="center">
                <v-row justify="center">
                  <v-col cols="12" align="center">
                    <h5 v-if="receipt.type === 'Payment'" class="titleSections">Pagador</h5>
                    <h5 v-else class="titleSections">Origem</h5>
                  </v-col>
                  <v-col cols="4">
                    <h5 class="labelData">Nome</h5>
                    <span class="valueData">{{ receipt.source.name.substring(0, 25) }}</span>
                  </v-col>
                  <v-col cols="4">
                    <h5 class="labelData">Agência</h5>
                    <span class="valueData">{{ receipt.source.branch }}</span>
                  </v-col>
                  <v-col cols="4">
                    <h5 class="labelData">Conta</h5>
                    <span class="valueData">{{ receipt.source.account }}</span>
                  </v-col>
                  <v-row v-if="receipt.type !== 'Payment'" class="ml-0 mr-0">
                    <v-col cols="12" align="center">
                      <h5 class="titleSections">Destino</h5>
                    </v-col>
                    <v-col
                      v-if="receipt.type === 'BankTransfer' || receipt.type === 'InternalTransfer'"
                    >
                      <h5 class="labelData">Nome</h5>
                      <span class="valueData">{{ receipt.destination.name }}</span>
                    </v-col>
                    <v-col :cols="receipt.type === 'InstantPayment' ? 6 : 4">
                      <h5 class="labelData">CPF/CNPJ</h5>
                      <span class="valueData">{{
                        this.service.maskToDocument(receipt.destination.document)
                      }}</span>
                    </v-col>
                    <v-col
                      cols="4"
                      v-if="receipt.type === 'BankTransfer' || receipt.type === 'InternalTransfer'"
                    >
                      <h5 class="labelData">Banco</h5>
                      <span class="valueData">{{ getBankName }}</span>
                    </v-col>
                    <v-col :cols="receipt.type === 'InstantPayment' ? 6 : 4">
                      <h5 class="labelData">Agência</h5>
                      <span class="valueData">{{ receipt.destination.branch }}</span>
                    </v-col>
                    <v-col :cols="receipt.type === 'InstantPayment' ? 6 : 4">
                      <h5 class="labelData">Conta</h5>
                      <span class="valueData" v-if="receipt.destination.digit">{{
                        `${receipt.destination.account}-${receipt.destination.digit}`
                      }}</span>
                      <span class="valueData" v-else>{{ receipt.destination.account }}</span>
                    </v-col>
                    <v-col :cols="receipt.type === 'InstantPayment' ? 6 : 4">
                      <h5 class="labelData">Tipo de Conta</h5>
                      <span class="valueData">{{ receipt.accountType }}</span>
                    </v-col>
                  </v-row>
                  <v-col v-if="receipt.type === 'Payment'" cols="12" class="pb-0 mb-0">
                    <h5 class="titleSections">Código de barras:</h5>
                  </v-col>
                  <v-col v-if="receipt.type === 'Payment'" cols="12">
                    <span class="valueData">{{ receipt.boleto.barCodeFormatted }}</span>
                  </v-col>
                  <v-col cols="12" class="pb-0 mb-0">
                    <h5 class="titleSections">Data e hora da efetivação da transferênca</h5>
                  </v-col>
                  <v-col cols="12">
                    <span class="valueData">{{
                      $moment(receipt.transactionDate).format("DD/MM/YYYY - HH:mm")
                    }}</span>
                  </v-col>
                  <v-col cols="12" class="pb-0 mb-0">
                    <h5 class="titleSections">Código de autenticação</h5>
                  </v-col>
                  <v-col cols="12">
                    <span class="valueData">{{ receipt.transactionId }}</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-container>
            <v-row v-if="!sendToEmail && !emailSuccess" justify="center">
              <v-col cols="12" md="6" aling="center">
                <v-btn
                  color="#00c1c1"
                  class="button-modal"
                  outlined
                  rounded
                  @click="seeSendEmail(true)"
                  >Enviar por e-mail</v-btn
                >
              </v-col>
              <v-col cols="12" md="6" aling="center">
                <v-btn @click="print" color="#00c1c1" class="button-modal" dark rounded
                  >Imprimir</v-btn
                >
              </v-col>
            </v-row>
            <v-row v-if="sendToEmail && !emailSuccess" justify="center">
              <v-col cols="3" md="2" class="mt-2" v-if="$vuetify.breakpoint.mdAndUp">
                <span @click="seeSendEmail(false)" class="cancelEmail">Cancelar</span>
              </v-col>
              <v-form cols="8" class="pt-3" lazy-validation aling="center" ref="form">
                <v-text-field
                  type="email"
                  class="sendEmail"
                  placeholder="Insira o e-mail"
                  v-model="email"
                  outlined
                  :rules="emailValidation"
                ></v-text-field>
              </v-form>
              <v-col class="send" @click="validateEmail" cols="2" aling="center">
                <img src="@/assets/send.png" alt />
              </v-col>
            </v-row>
            <v-col v-if="$vuetify.breakpoint.smAndDown && sendToEmail && !emailSuccess" cols="12">
              <v-row justify="center">
                <span @click="seeSendEmail(false)" class="cancelEmail">Cancelar</span>
              </v-row>
            </v-col>
            <v-row v-if="!sendToEmail && emailSuccess" justify="center">
              <v-col cols="12">
                <span class="msgSuccess">
                  <b>Email enviado com sucesso!</b> Clique abaixo para enviar um novo email:
                </span>
              </v-col>
              <v-col cols="12" md="6" aling="center">
                <v-btn color="#00c1c1" class="button-modal" outlined rounded @click="closeModal"
                  >Fechar</v-btn
                >
              </v-col>
              <v-col cols="12" md="6" aling="center">
                <v-btn @click="seeSendEmail(true)" color="#00c1c1" class="button-modal" dark rounded
                  >Enviar novo e-mail</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import service from "../../../service";

export default {
  props: {
    dialog: Boolean,
    pixRecebido: String
  },
  data() {
    return {
      service,
      success: false,
      sendToEmail: false,
      email: "",
      emailValidation: [
        valueInput => !!valueInput || "Insira o e-mail",
        valueInput => !valueInput || /.+@.+\..+/.test(valueInput) || "E-mail inválido"
      ]
    };
  },
  computed: {
    ...mapGetters({
      receipt: "DigitalAccount/getReceiptTransfer",
      emailSuccess: "DigitalAccount/getSendEmail",
      getBanks: "Registration/getBanks"
    }),
    getBankName() {
      if (this.receipt.destination.bank === 9999) {
        return "Finago";
      }
      const bank = this.getBanks.find(item => item.key === this.receipt.destination.bank);
      if (bank.value) {
        return bank.value;
      } else {
        return null;
      }
    }
  },
  methods: {
    ...mapActions({
      sendEmail: "DigitalAccount/sendEmail",
      setBanks: "Registration/setBanks"
    }),
    ...mapMutations({
      clearEmailSuccess: "DigitalAccount/CLEAR_SEND_EMAIL"
    }),
    validateEmail() {
      if (this.$refs.form.validate()) {
        this.sendTransferToEmail();
      }
    },
    print() {
      const prtHtml = document.getElementById("contentReceipt").innerHTML;

      const styles = [...document.querySelectorAll('link[rel="stylesheet"], style')].reduce(
        (acc, item) => acc + item.outerHTML,
        ""
      );

      const printWindow = window.open(
        "",
        "",
        "left=0,top=0,height=900,toolbar=100,scrollbars=0,status=0"
      );

      printWindow.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${styles}
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`);
      this.$emit("closeModal", false);
      //printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      printWindow.close();

      if (window.stop) {
        location.reload(); //triggering unload (e.g. reloading the page) makes the print dialog appear
        window.stop(); //immediately stop reloading
      }
      printWindow.close();
    },
    seeSendEmail(bol) {
      this.sendToEmail = bol;
      if (!bol) this.email = "";
      else this.clearEmailSuccess();
    },
    async sendTransferToEmail() {
      await this.sendEmail({
        id: this.receipt.transactionId,
        email: this.email
      });
      this.seeSendEmail(false);
    },
    closeModal() {
      this.sendToEmail = false;
      this.$emit("closeModalReceipt");
    }
  },
  async created() {
    await this.setBanks();
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/styles/digitalAccount/modalReceipt.scss";
</style>
